<template>
  <div class="abonos-facturas mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Abonos por Cobrar
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-autocomplete
                      v-model="id"
                      v-on="on"
                      v-bind="attrs"
                      dense
                      outlined
                      rounded
                      :loading="isLoading"
                      item-value="id"
                      :items="lista"
                      no-filter
                      label="Selecciona un cliente"
                      ref="clientes"
                      hide-no-data
                      @update:search-input="customFilter"
                      @change="loadFacturasCredito()"
                    ></v-autocomplete>
                  </template>
                  <span>Filtra tus clientes por nombre o cedula / ID</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="12" sm="">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Facturas pendientes
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :search="search"
                      :items="facturas_pendientes"
                      :footer-props="{ itemsPerPageText: 'Facturas' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              append-icon="mdi-magnify"
                              label="Buscar"
                              class="mt-6"
                              v-model="search"
                            ></v-text-field>
                          </v-col>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:item.descuento="{ item }">
                        {{ item.descuento | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.notas="{ item }">
                        {{ item.notas | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              color="primary"
                              v-on="on"
                              v-bind="attrs"
                              @click="
                                $router.push('/detalleAbonosFactura/' + item.id)
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver factura</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:body.append>
                        <tr>
                          <th colspan="7">Total</th>
                          <th colspan="2">
                            {{ total_pendientes | currency }}
                          </th>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Facturas pagadas
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :search="search_p"
                      :items="facturas_pagadas"
                      :footer-props="{ itemsPerPageText: 'Facturas' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              append-icon="mdi-magnify"
                              label="Buscar"
                              class="mt-6"
                              v-model="search"
                            ></v-text-field>
                          </v-col>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:item.descuento="{ item }">
                        {{ item.descuento | currency }}
                      </template>
                      <template v-slot:item.abonos="{ item }">
                        {{ item.abonos | currency }}
                      </template>
                      <template v-slot:item.notas="{ item }">
                        {{ item.notas | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.acciones="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              color="primary"
                              v-on="on"
                              v-bind="attrs"
                              @click="
                                $router.push('/detalleAbonosFactura/' + item.id)
                              "
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver factura</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "abonosFacturas",
  data: () => ({
    // Pagination
    search_c: "",
    isLoading: false,
    clientes: [],
    id: null,
    search: "",
    search_p: "",
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Factura",
        value: "folio",
      },
      {
        text: "Cliente",
        value: "cliente",
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
      },
      {
        text: "Descuento",
        value: "descuento",
        sortable: false,
      },
      {
        text: "Abonos",
        value: "abonos",
        sortable: false,
      },
      {
        text: "Notas crédito",
        value: "notas",
        sortable: false,
      },
      {
        text: "Saldo",
        value: "saldo",
        sortable: false,
      },
      {
        text: "",
        value: "acciones",
        sortable: false,
      },
    ],
    facturas: [],
  }),
  methods: {
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search_c,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadFacturasCredito() {
      const body = {
        route: "/facturas_credito_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.facturas = response.data.data;
      });
    },
    customFilter(item) {
      this.search_c = item;
      if (this.search_c != null || this.search_c != undefined) {
        if (this.search_c != "") this.loadClientesLite();
      }
    },
  },
  created() {
    // this.loadClientesLite();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion", "informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    lista_facturas() {
      return this.facturas.map((f) => {
        return {
          id: f.id,
          fecha: moment(f.fecha).format("ll"),
          folio: this.$options.filters.folio(f.folio),
          cliente: f.cliente,
          total: parseFloat(f.total),
          descuento: parseFloat(f.descuento),
          abonos: parseFloat(f.abonos),
          notas: parseFloat(f.notas),
          saldo:
            parseFloat(f.total) -
            parseFloat(f.descuento) -
            parseFloat(f.abonos) -
            parseFloat(f.notas),
        };
      });
    },
    facturas_pendientes() {
      return this.lista_facturas.filter((f) => {
        return f.saldo > 0;
      });
    },
    total_pendientes() {
      return this.facturas_pendientes.reduce((i, item) => {
        return i + item.saldo;
      }, 0);
    },
    facturas_pagadas() {
      return this.lista_facturas.filter((f) => {
        return f.saldo <= 0;
      });
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
  },
};
</script>
