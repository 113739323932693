var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"abonos-facturas mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Abonos por Cobrar ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({ref:"clientes",attrs:{"dense":"","outlined":"","rounded":"","loading":_vm.isLoading,"item-value":"id","items":_vm.lista,"no-filter":"","label":"Selecciona un cliente","hide-no-data":""},on:{"update:search-input":_vm.customFilter,"change":function($event){return _vm.loadFacturasCredito()}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}},'v-autocomplete',attrs,false),on))]}}])},[_c('span',[_vm._v("Filtra tus clientes por nombre o cedula / ID")])])],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":""}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" Facturas pendientes ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.facturas_pendientes,"footer-props":{ itemsPerPageText: 'Facturas' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.descuento))+" ")]}},{key:"item.abonos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.abonos))+" ")]}},{key:"item.notas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.notas))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/detalleAbonosFactura/' + item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver factura")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"7"}},[_vm._v("Total")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total_pendientes))+" ")])])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":""}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" Facturas pagadas ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search_p,"items":_vm.facturas_pagadas,"footer-props":{ itemsPerPageText: 'Facturas' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.descuento))+" ")]}},{key:"item.abonos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.abonos))+" ")]}},{key:"item.notas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.notas))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/detalleAbonosFactura/' + item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver factura")])])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }